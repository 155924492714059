export enum RouteConstants {
  HOME = '/dashboard',
  SUPPORT = '/support',
  PLAN = '/plan',
  MEMBERS = '/plan/members',
  MEMBEROVERVIEW = '/member/members',
  OVERVIEW = '/plan/plan-overview',
  EXPLORE = '/plan/explore',
  GUIDE = '/support/guide',
  TOOLKIT = '/support/toolkit',
  RESOURCE = '/support/resource',
  CHALLENGES = '/challenges',
  ADDMEMBER_ADVISER='/plan/plan-search/add-member',
  ADDMEMBER_EMPLOYER='/plan/members/new',
  REMOVE_MEMBER='/remove-member',
  REMOVE_DEPENDANT='/remove-dependant'
}
