import { useLocation } from 'react-router-dom';
import { RouteConstants } from '../../constants/routes';

export const FooterInformationBar = props => {
  const footerProps = props?.data;
  const [footerInformation, isAdviser] = footerProps;

  const location = useLocation();

  const addTransactionalPaths =
    location.pathname.startsWith(RouteConstants.ADDMEMBER_ADVISER) || location.pathname.startsWith(RouteConstants.ADDMEMBER_EMPLOYER);
  const removeTransactionalPaths =
    location.pathname.includes(RouteConstants.REMOVE_MEMBER) || location.pathname.includes(RouteConstants.REMOVE_DEPENDANT);

  const display = !(addTransactionalPaths || removeTransactionalPaths);

  const clearStorage = () => {
    const returnPathKey = 'returnTo.path';
    const returnSearchKey = 'returnTo.search';
    sessionStorage.removeItem(returnPathKey);
    sessionStorage.removeItem(returnSearchKey);
    localStorage.clear();
  };

  const displayFooterBar = () => {
    const footerBarFields = isAdviser
      ? ['AdviserFooterSection1', 'AdviserFooterLink', 'AdviserFooterSection2']
      : ['FooterSection1', 'FooterLink', 'FooterSection2'];
    return (
      <p>
        {footerInformation?.[footerBarFields[0]]?.value}{' '}
        <a href={footerInformation?.[footerBarFields[1]]?.value} data-testid="footerLink" onClick={clearStorage} target="_self" style={{ color: '#f41c5e' }}>
          here.
        </a>{' '}
        {footerInformation?.[footerBarFields[2]]?.value}
      </p>
    );
  };

  return (
    display && (
      <footer className="vds-information-bar vds-information-bar--bottom">
        <div className="vds-information-bar__inner">
          <div className="vds-information-bar__section">{displayFooterBar()}</div>
        </div>
      </footer>
    )
  );
};

export default FooterInformationBar;
