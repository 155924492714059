import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import Loader from '../../global/Loader.tsx';

export const LogoutPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  if (auth.error) {
    throw auth.error;
  }

  const logoutLoader = <Loader loadingTitle={'Logging out'} loadingSubtitle={'You will be redirected shortly...'} />;

  if (auth.isLoading) {
    return logoutLoader;
  }

  if (auth.isAuthenticated) {
    auth.signoutRedirect();
    localStorage.removeItem('HideNotificationKey');
    return logoutLoader;
  } else {
    navigate('/', { replace: true });
  }
};
