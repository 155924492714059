import { useQuery } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import vitalitylogo from '../../assets/vitality-logo-ellipse.svg';
import { adviserHost, logoUrl } from '../../constants/application-constants';

import ReactErrorBoundary from '../../global/error/ReactErrorBoundary';
import { FooterInformationBar } from '../Footer/FooterInformationBar';
import { Footer } from '../Footer/FooterNavigation';
import { NavLink } from './SideNavigationLinks';
import { FOOTER_INFORMATION_BAR_QUERY } from './footer-information-bar';
import { SITE_NAVIGATION_QUERY } from './side-navigation-query';

export const SideNavigation = () => {
  const { data } = useQuery(SITE_NAVIGATION_QUERY);
  const footerInformationBarResponse = useQuery(FOOTER_INFORMATION_BAR_QUERY);

  const userType = localStorage.getItem('userType')?.toString();
  const isAdviser = userType?.toLocaleLowerCase() === adviserHost.toLocaleLowerCase();

  let footerInformation;
  if (footerInformationBarResponse?.data) {
    const { item } = footerInformationBarResponse.data;
    footerInformation = item;
  }
  let SideNavigationData;
  let FooterNavigationDetails;
  let WebsiteHeader;
  if (data && Object.keys(data).length != 0) {
    const { item } = data;
    const { employerWebsiteTitle } = item;
    const { adviserWebsiteTitle } = item;
    const { navigationJson } = isAdviser ? item?.adviserNavigation || undefined : item?.navigation || undefined;
    SideNavigationData = navigationJson;
    FooterNavigationDetails = item;
    WebsiteHeader = isAdviser ? adviserWebsiteTitle : employerWebsiteTitle;
  }
  return (
    <div className="vds-dashboard vds-cta-stuck">
      <div className="vds-dashboard__inner">
        <aside className="vds-dashboard__navigation">
          <nav className="vds-primary-navigation-side">
            <div className="vds-primary-navigation-side__inner">
              <div className="vds-primary-navigation-side__top-bar">
                <header className="vds-primary-navigation-side__header">
                  <a className="vds-primary-navigation-side__header-link" href={logoUrl} data-testid="header">
                    <img className="vds-primary-navigation-side__logo" src={vitalitylogo} height="48" width="48" alt="Vitality logo" />
                    {WebsiteHeader?.value}
                  </a>

                  <button className="vds-primary-navigation-side__menu-toggle" type="button" title="Open menu" data-panel-target="#primary-nav">
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </header>
              </div>

              <aside
                id="primary-nav"
                className="vds-primary-navigation-side__main vds-off-canvas vds-off-canvas--slide-out vds-off-canvas--closed"
                aria-live="polite"
                aria-atomic="true">
                <header className="vds-primary-navigation-side__header">
                  <a className="vds-primary-navigation-side__header-link" href={logoUrl} data-testid="aside">
                    <img className="vds-primary-navigation-side__logo" src={vitalitylogo} height="48" width="48" alt="Vitality logo" />
                    {WebsiteHeader?.value}
                  </a>

                  <button className="vds-primary-navigation-side__menu-toggle" type="button" title="Close menu" data-panel-target="#primary-nav">
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </header>

                <div className="vds-primary-navigation-side__main-inner">
                  <ul className="vds-primary-navigation-side__list">
                    {data && Object.keys(data).length != 0 && (
                      <>
                        {SideNavigationData.map((item, index) => {
                          return (
                            <NavLink
                              id={index}
                              title={item.fields.Title}
                              path={item.fields.Path}
                              icon={item.fields.Icon}
                              key={index}
                              isNew={item.fields.IsNew}
                              isComingSoon={item.fields.IsComingSoon}
                            />
                          );
                        })}
                      </>
                    )}
                  </ul>
                  <Footer data={[FooterNavigationDetails, isAdviser]} />
                </div>
              </aside>
            </div>
          </nav>
        </aside>
        <main className="vds-dashboard__content">
          <div className="vds-dashboard__content-inner">
            <ReactErrorBoundary>
              <Outlet />
            </ReactErrorBoundary>
          </div>
          <FooterInformationBar data={[footerInformation, isAdviser]} />
        </main>
      </div>
    </div>
  );
};
