import { gql } from '@apollo/client';

export const SITE_NAVIGATION_QUERY = gql`
  query SideNavigationQuery {
    item(path: "/sitecore/content/wc-v4b/shell/Navigation/side-navigation", language: "en") {
      navigation: field(name: "PrimaryNavigationLinks") {
        navigationJson: jsonValue
      }
      footerNavigation: field(name: "PrimaryFooterLinks") {
        footerNavigationJson: jsonValue
      }
      employerWebsiteTitle: field(name: "EmployerWebsiteHeader") {
        value
      }
      copyRightInfo: field(name: "CopyRightText") {
        value
      }
      EmployerUrlRedirect: field(name: "EmployerUrlRedirect") {
        value
      }
      adviserNavigation: field(name: "PrimaryNavigationLinksAdviser") {
        navigationJson: jsonValue
      }
      adviserfooterNavigation: field(name: "PrimaryFooterLinksAdviser") {
        footerNavigationJson: jsonValue
      }
      adviserWebsiteTitle: field(name: "AdviserWebsiteHeader") {
        value
      }
      adviserUrlRedirect: field(name: "AdviserUrlRedirect") {
        value
      }
    }
  }
`;
