import { useQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { Navigate } from 'react-router-dom';
import { SITE_NAVIGATION_QUERY } from '../components/Navigation/side-navigation-query';
import { adviserHost } from '../constants/application-constants';
import { GtmId } from '../constants/gtm';

export const Overview = () => {
  const tagManagerArgs = {
    gtmId: GtmId,
  };

  TagManager.initialize(tagManagerArgs);
  const { data } = useQuery(SITE_NAVIGATION_QUERY);
  const host = localStorage.getItem('userType');
  const isAdviser = host === adviserHost;
  let redirectUrl;
  if (data && Object.keys(data).length !== 0) {
    const { item } = data;
    const { EmployerUrlRedirect } = item;
    const { adviserUrlRedirect } = item;
    redirectUrl = isAdviser ? adviserUrlRedirect : EmployerUrlRedirect;
  }
  return <Navigate to={redirectUrl?.value} />;
};
