import { gql } from '@apollo/client';

export const FOOTER_INFORMATION_BAR_QUERY = gql`
  query FooterInformationBarQuery {
    item(path: "/sitecore/content/wc-v4b/shell/Navigation/footer-information-bar", language: "EN") {
      FooterSection1: field(name: "FooterSection1") {
        value
      }
      FooterLink: field(name: "FooterLink") {
        value
      }
      FooterSection2: field(name: "FooterSection2") {
        value
      }
      FooterHelpText: field(name: "FooterHelpText") {
        value
      }
      FooterHelpTextLink: field(name: "FooterHelpTextLink") {
        jsonValue
      }
      AdviserFooterSection1: field(name: "AdviserFooterSection1") {
        value
      }
      AdviserFooterLink: field(name: "AdviserFooterLink") {
        value
      }
      AdviserFooterSection2: field(name: "AdviserFooterSection2") {
        value
      }
      AdviserFooterHelpText: field(name: "AdviserFooterHelpText") {
        value
      }
      AdviserFooterHelpTextLink: field(name: "AdviserFooterHelpTextLink") {
        jsonValue
      }
    }
  }
`;
