import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RouteConstants } from '../../constants/routes';

const HOME_MENU_INDEX = 0;
const PLAN_MENU_INDEX = 1;
const MEMBER_MENU_INDEX = 2;
const CHALLENGES_MENU_INDEX = 3;

export const NavLink = ({ id, title, path, icon, isNew, isComingSoon }) => {
  const [active, setActive] = useState<number>();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith(RouteConstants.HOME)) {
      setActive(HOME_MENU_INDEX);
    } else if (pathname.startsWith(RouteConstants.MEMBEROVERVIEW) || pathname.startsWith(RouteConstants.MEMBERS)) {
      setActive(MEMBER_MENU_INDEX);
    } else if (pathname.startsWith(RouteConstants.OVERVIEW)) {
      setActive(PLAN_MENU_INDEX);
    } else if (pathname.startsWith(RouteConstants.CHALLENGES)) {
      setActive(CHALLENGES_MENU_INDEX);
    } else {
      setActive(PLAN_MENU_INDEX);
    }
  }, [pathname]);

  function createNavigateFunction(index: number) {
    return () => {
      setActive(index);
    };
  }

  const wrapper = (
    <>
      <i className={icon?.value}></i>
      <div className="vds-primary-navigation-side__link-text">{title?.value}</div>
      {isNew?.value && (
        <span className="vds-pill vds-pill--xsmall vds-pill--success">
          <i className="fa-solid fa-sparkles vds-pill__icon"></i>
          <span className="vds-pill__content">New</span>
        </span>
      )}
      {isComingSoon?.value && (
        <span className="vds-pill vds-pill--xsmall vds-pill--promo">
          <span className="vds-pill__content">COMING SOON</span>
        </span>
      )}
    </>
  );

  return (
    <li key={id} className={'vds-primary-navigation-side__item' + `${active == id ? ' vds-primary-navigation-side__item--active' : ''}`}>
      {!isComingSoon?.value ? (
        <Link
          to={path?.value}
          data-testid="sideLink"
          onClick={createNavigateFunction(id)}
          className="vds-primary-navigation-side__link"
          aria-current="true">
          {wrapper}
        </Link>
      ) : (
        <span className="vds-primary-navigation-side__link">{wrapper}</span>
      )}
    </li>
  );
};
